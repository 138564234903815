import React, { useEffect, useState } from "react";
import ResultField from "../ResultField";
import { checkValue } from "../../../helpers";
import TextField from "../TextField";
import Button from "../Button";
import PropTypes from "prop-types";
import { LINK_GENERATOR_VARIANTS } from "../../../helpers/constants";

const TargetedAsins = (props) => {
  const {
    marketplace,
    trackTitle,
    handleClickButton,
    initialState,
    setInitialState,
  } = props;
  const [asin1, setAsin1] = useState(initialState?.asin1 || "");
  const [asin2, setAsin2] = useState(initialState?.asin2 || "");
  const [asin3, setAsin3] = useState(initialState?.asin3 || "");
  const [errors, setErrors] = useState(initialState?.errors || []);
  const [result, setResult] = useState(initialState?.result || "");

  useEffect(() => {
    setInitialState(LINK_GENERATOR_VARIANTS.TARGETED_ASIN_SEARCH, {
      asin1,
      asin2,
      asin3,
      errors,
      result,
    });
  }, [asin1, asin2, asin3, errors, result]);

  const changeAsin1 = (event) => setAsin1(event.target.value);
  const changeAsin2 = (event) => setAsin2(event.target.value);
  const changeAsin3 = (event) => setAsin3(event.target.value);

  const getResult = () => {
    const errorsArray = fullChecking();
    const isErrors = !!errorsArray.filter(Boolean).length;
    if (!isErrors) {
      const formattedAsins = [asin1, asin2, asin3].join("%7C+");
      const res = `https://www.${marketplace}/s/?k=${formattedAsins}&ref=nb_sb_noss`;
      setResult(res);
      handleClickButton("create", `${trackTitle}_success`);
    } else {
      setResult("");
      handleClickButton("create", `${trackTitle}_failed`);
    }
  };

  const fullChecking = () => {
    const errors = [
      checkValue("asin", asin1, true),
      checkValue("asin", asin2, true),
      checkValue("asin", asin3, true),
    ];
    setErrors(errors);
    return errors;
  };

  return (
    <div className="variant-content">
      <div className="variant-content__example">
        https://{marketplace}
        /s/?k=0987654321%7C+1234567890%7C+0987654321&ref=nb_sb_noss
      </div>
      <div className="variant-content__form">
        <TextField
          value={asin1}
          onChange={changeAsin1}
          placeholder="ASIN"
          error={errors[0]}
          isMandatory
        />
        <TextField
          value={asin2}
          onChange={changeAsin2}
          placeholder="ASIN"
          error={errors[1]}
          isMandatory
        />
        <TextField
          value={asin3}
          onChange={changeAsin3}
          placeholder="ASIN"
          error={errors[2]}
          isMandatory
        />
        <Button
          text="Create Link"
          onClick={getResult}
          isForm
          customClass="create-btn"
        />
      </div>
      <ResultField
        resultUrl={result}
        handleCopy={() => handleClickButton("copy", trackTitle)}
        handleOpen={() => handleClickButton("open", trackTitle)}
      />
    </div>
  );
};

TargetedAsins.propTypes = {
  marketplace: PropTypes.string,
  trackTitle: PropTypes.string,
  handleClickButton: PropTypes.func,
  initialState: PropTypes.object,
  setInitialState: PropTypes.func,
};

export default TargetedAsins;
