import React, { useEffect, useState } from "react";
import ResultField from "../ResultField";
import { checkValue, pasteErrorClasses } from "../../../helpers";
import TextField from "../TextField";
import Button from "../Button";
import PropTypes from "prop-types";
import { LINK_GENERATOR_VARIANTS } from "../../../helpers/constants";

const TogetherASINQuantity = (props) => {
  const {
    marketplace,
    trackTitle,
    handleClickButton,
    initialState,
    setInitialState,
  } = props;
  const [pair1, setPair1] = useState({
    asin: initialState?.pair1.asin || "",
    quantity: initialState?.pair1.quantity || "",
  });
  const [pair2, setPair2] = useState({
    asin: initialState?.pair2.asin || "",
    quantity: initialState?.pair2.quantity || "",
  });
  const [pair3, setPair3] = useState({
    asin: initialState?.pair3.asin || "",
    quantity: initialState?.pair3.quantity || "",
  });
  const [pair4, setPair4] = useState({
    asin: initialState?.pair4.asin || "",
    quantity: initialState?.pair4.quantity || "",
  });
  const [errors, setErrors] = useState(initialState?.errors || []);
  const [result, setResult] = useState(initialState?.result || "");

  useEffect(() => {
    setInitialState(LINK_GENERATOR_VARIANTS.BUY_TOGETHER, {
      pair1,
      pair2,
      pair3,
      pair4,
      errors,
      result,
    });
  }, [pair1, pair2, pair3, pair4, errors, result]);

  const changePair1 = (event, key) =>
    setPair1({
      asin: key === "asin" ? event.target.value : pair1.asin,
      quantity: key === "quantity" ? event.target.value : pair1.quantity,
    });
  const changePair2 = (event, key) =>
    setPair2({
      asin: key === "asin" ? event.target.value : pair2.asin,
      quantity: key === "quantity" ? event.target.value : pair2.quantity,
    });
  const changePair3 = (event, key) =>
    setPair3({
      asin: key === "asin" ? event.target.value : pair3.asin,
      quantity: key === "quantity" ? event.target.value : pair3.quantity,
    });
  const changePair4 = (event, key) =>
    setPair4({
      asin: key === "asin" ? event.target.value : pair4.asin,
      quantity: key === "quantity" ? event.target.value : pair4.quantity,
    });

  const getResult = () => {
    const errorsArray = fullChecking();
    const isErrors = !!errorsArray.filter(Boolean).length;
    if (!isErrors) {
      const formattedPairs = [pair1, pair2, pair3, pair4].reduce(
        (accum, { asin, quantity }, index) => {
          let formattedPair = "";
          if (asin && quantity) {
            formattedPair = `${index === 0 ? "" : "&"}ASIN.${
              index + 1
            }=${asin}&Quantity.${index + 1}=${quantity}`;
          }
          return accum.concat(formattedPair);
        },
        ""
      );
      const res = `https://www.${marketplace}/gp/aws/cart/add.html?${formattedPairs}`;
      setResult(res);
      handleClickButton("create", `${trackTitle}_success`);
    } else {
      setResult("");
      handleClickButton("create", `${trackTitle}_failed`);
    }
  };

  const fullChecking = () => {
    const errors = [
      checkValue("asin", pair1.asin, true),
      checkValue("quantity", pair1.quantity, true),
      checkValue("asin", pair2.asin, true),
      checkValue("quantity", pair2.quantity, true),
      checkValue("asin", pair3.asin, false),
      checkValue("quantity", pair3.quantity, false),
      checkValue("asin", pair4.asin, false),
      checkValue("quantity", pair4.quantity, false),
    ];
    setErrors(errors);
    return errors;
  };

  return (
    <div className="variant-content">
      <div className="variant-content__example">
        Example: https://www.{marketplace}
        /gp/aws/cart/add.html?ASIN.1=B00SUZWGHM&Quantity.1=1&ASIN.2=B00809ERAM&Quantity.2=1
      </div>
      <div className="variant-content__form">
        <TextField
          value={pair1.asin}
          onChange={(event) => changePair1(event, "asin")}
          placeholder="ASIN 1"
          error={errors[0] || errors[1]}
          isTwoInputs
          customClass={pasteErrorClasses(errors[0], errors[1])}
          secondInputValue={pair1.quantity}
          secondInputOnChange={(event) => changePair1(event, "quantity")}
          secondInputPlaceholder="Quantity 1"
          isMandatory
        />
        <TextField
          value={pair2.asin}
          onChange={(event) => changePair2(event, "asin")}
          placeholder="ASIN 2"
          error={errors[2] || errors[3]}
          isTwoInputs
          customClass={pasteErrorClasses(errors[2], errors[3])}
          secondInputValue={pair2.quantity}
          secondInputOnChange={(event) => changePair2(event, "quantity")}
          secondInputPlaceholder="Quantity 2"
          isMandatory
        />
        <TextField
          value={pair3.asin}
          onChange={(event) => changePair3(event, "asin")}
          placeholder="ASIN 3"
          error={errors[4] || errors[5]}
          isTwoInputs
          customClass={pasteErrorClasses(errors[4], errors[5])}
          secondInputValue={pair3.quantity}
          secondInputOnChange={(event) => changePair3(event, "quantity")}
          secondInputPlaceholder="Quantity 3"
        />
        <TextField
          value={pair4.asin}
          onChange={(event) => changePair4(event, "asin")}
          placeholder="ASIN 4"
          error={errors[6] || errors[7]}
          isTwoInputs
          customClass={pasteErrorClasses(errors[6], errors[7])}
          secondInputValue={pair4.quantity}
          secondInputOnChange={(event) => changePair4(event, "quantity")}
          secondInputPlaceholder="Quantity 4"
        />
        <Button
          text="Create Link"
          onClick={getResult}
          customClass="w-100 create-btn"
          isForm
        />
      </div>
      <ResultField
        resultUrl={result}
        customClass="result-together"
        handleCopy={() => handleClickButton("copy", trackTitle)}
        handleOpen={() => handleClickButton("open", trackTitle)}
      />
    </div>
  );
};

TogetherASINQuantity.propTypes = {
  marketplace: PropTypes.string,
  trackTitle: PropTypes.string,
  handleClickButton: PropTypes.func,
  initialState: PropTypes.object,
  setInitialState: PropTypes.func,
};

export default TogetherASINQuantity;
