import React, { useEffect, useState } from "react";
import ResultField from "../ResultField";
import { checkValue } from "../../../helpers";
import TextField from "../TextField";
import Button from "../Button";
import PropTypes from "prop-types";
import { LINK_GENERATOR_VARIANTS } from "../../../helpers/constants";

const AddCart = (props) => {
  const {
    marketplace,
    trackTitle,
    handleClickButton,
    initialState,
    setInitialState,
  } = props;
  const [quantity, setQuantity] = useState(initialState?.quantity || "");
  const [asin, setAsin] = useState(initialState?.asin || "");
  const [errors, setErrors] = useState(initialState?.errors || []);
  const [result, setResult] = useState(initialState?.result || "");

  useEffect(() => {
    setInitialState(LINK_GENERATOR_VARIANTS.CART, {
      asin,
      quantity,
      errors,
      result,
    });
  }, [asin, quantity, errors, result]);

  const changeQuantity = (event) => setQuantity(event.target.value);
  const changeAsin = (event) => setAsin(event.target.value);
  const getResult = () => {
    const errorsArray = fullChecking();
    const isErrors = !!errorsArray.filter(Boolean).length;
    if (!isErrors) {
      const res = `https://www.${marketplace}/gp/aws/cart/add.html?ASIN.1=${asin}&Quantity.1=${quantity}`;
      setResult(res);
      handleClickButton("create", `${trackTitle}_success`);
    } else {
      setResult("");
      handleClickButton("create", `${trackTitle}_failed`);
    }
  };

  const fullChecking = () => {
    const errors = [
      checkValue("asin", asin, true),
      checkValue("quantity", quantity, true),
    ];
    setErrors(errors);
    return errors;
  };

  return (
    <div className="variant-content">
      <div className="variant-content__example">
        Example: https://www.{marketplace}
        /gp/aws/cart/add.html?ASIN.1=B07NY65VXH&Quantity.1=1
      </div>
      <div className="variant-content__form">
        <TextField
          value={asin}
          onChange={changeAsin}
          placeholder="ASIN"
          error={errors[0]}
          isMandatory
        />
        <TextField
          value={quantity}
          onChange={changeQuantity}
          placeholder="Quantity"
          error={errors[1]}
          isMandatory
        />
        <Button
          text="Create Link"
          onClick={getResult}
          isForm
          customClass="create-btn"
        />
      </div>
      <ResultField
        resultUrl={result}
        handleCopy={() => handleClickButton("copy", trackTitle)}
        handleOpen={() => handleClickButton("open", trackTitle)}
      />
    </div>
  );
};

AddCart.propTypes = {
  marketplace: PropTypes.string,
  trackTitle: PropTypes.string,
  handleClickButton: PropTypes.func,
  initialState: PropTypes.object,
  setInitialState: PropTypes.func,
};

export default AddCart;
