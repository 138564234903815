import React from "react";
import PG_ASU_PROS_CONTENT from "./index.content";
import "./index.scss";

const Pros = () => {
  return (
    <section className="PgASU-Pros">
      <div className="container">
        <div className="PgASU-Pros__card">
          {PG_ASU_PROS_CONTENT.map(({ name, description }) => (
            <div className="PgASU-Pros-item" key={name}>
              <p className="PgASU-Pros-item__name">{name}</p>
              <p className="PgASU-Pros-item__description">{description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Pros;
