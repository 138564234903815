import React from "react";
import "./index.scss";

const HomeIntro = () => {
  return (
    <section className="PgASU-HomeIntro">
      <div className="container">
        <h1 className="PgASU-HomeIntro__title">Amazon Super URL Tool</h1>
        <p className="PgASU-HomeIntro__subtitle">
          Create Smart Links That Boost Your Amazon Search Rankings and Increase
          Product Sales!
        </p>
      </div>
    </section>
  );
};

export default HomeIntro;
