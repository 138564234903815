import React from "react"

const PG_ASU_PROS_CONTENT = [
  {
    name: "Optimize your organic search positions",
    description:
      "Improve your listing visibility on different keywords to attract more visitors and increased sales",
  },
  {
    name: "Boost your conversion rates",
    description:
      "Refer users from your pre-landing pages and ad campaigns directly to your products added to the Amazon shopping cart",
  },
  {
    name: "Increase your sales",
    description: "Give users direct links to several of your products at once",
  },
]

export default PG_ASU_PROS_CONTENT
