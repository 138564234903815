import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const TextField = ({
  placeholder = " ",
  onChange,
  value,
  isResult,
  isMandatory,
  error,
  customClass,
  isTwoInputs,
  secondInputPlaceholder,
  secondInputValue,
  secondInputOnChange,
  resultRef,
}) => {
  const classes = [
    "input-field",
    isTwoInputs ? "input-field-two" : "",
    customClass,
    error ? "error" : "",
  ]
    .filter(Boolean)
    .join(" ");

  return isResult ? (
    <textarea
      ref={resultRef}
      className="textarea"
      rows="3"
      value={value}
      readOnly
    />
  ) : (
    <div className={`${classes}`}>
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        className="input"
        onChange={onChange}
      />
      <span className="input-additional">
        {isMandatory ? "(mandatory)" : "(optional)"}
      </span>
      {isTwoInputs ? (
        <input
          type="text"
          value={secondInputValue}
          placeholder={secondInputPlaceholder}
          className="input"
          onChange={secondInputOnChange}
        />
      ) : null}
      <span className="input-error">{error || null}</span>
    </div>
  );
};

TextField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  isResult: PropTypes.bool,
  isMandatory: PropTypes.bool,
  secondInputValue: PropTypes.string,
  secondInputOnChange: PropTypes.func,
  secondInputPlaceholder: PropTypes.string,
  isTwoInputs: PropTypes.bool,
  resultRef: PropTypes.any,
  error: PropTypes.string,
  customClass: PropTypes.string,
  placeholder: PropTypes.string,
};

export default TextField;
