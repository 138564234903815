import React, { useEffect, useState } from "react";
import ResultField from "../ResultField";
import {
  checkValue,
  transformKeywordsStringToUrlFormat,
} from "../../../helpers";
import TextField from "../TextField";
import Button from "../Button";
import PropTypes from "prop-types";
import { LINK_GENERATOR_VARIANTS } from "../../../helpers/constants";

const HiddenKeywords = (props) => {
  const {
    marketplace,
    trackTitle,
    handleClickButton,
    initialState,
    setInitialState,
  } = props;
  const [keywords, setKeywords] = useState(initialState?.keywords || "");
  const [asin, setAsin] = useState(initialState?.asin || "");
  const [errors, setErrors] = useState(initialState?.errors || []);
  const [result, setResult] = useState(initialState?.result || "");

  useEffect(() => {
    setInitialState(LINK_GENERATOR_VARIANTS.KEYWORDS, {
      asin,
      keywords,
      errors,
      result,
    });
  }, [asin, keywords, errors, result]);

  const changeKeywords = (event) => setKeywords(event.target.value);
  const changeAsin = (event) => setAsin(event.target.value);

  const getResult = () => {
    const errorsArray = fullChecking();
    const isErrors = !!errorsArray.filter(Boolean).length;
    if (!isErrors) {
      const formattedKeywords = transformKeywordsStringToUrlFormat(keywords);
      const res = `https://www.${marketplace}/s/ref=nb_sb_noss_1?url=search-alias%3Daps&field-keywords=${formattedKeywords}&hidden-keywords=${asin}`;
      setResult(res);
      handleClickButton("create", `${trackTitle}_success`);
    } else {
      setResult("");
      handleClickButton("create", `${trackTitle}_failed`);
    }
  };

  const fullChecking = () => {
    const errors = [
      checkValue("keywords", keywords, true),
      checkValue("asin", asin, true),
    ];
    setErrors(errors);
    return errors;
  };

  return (
    <div className="variant-content">
      <div className="variant-content__example">
        Example: https://www.{marketplace}
        /s/ref=nb_sb_noss_1?url=search-alias%3Daps&field-keywords=iphone+xr+case&hidden-keywords=B07JD78YWN&rh=i:aps,ssx:relevance
      </div>
      <div className="variant-content__form">
        <TextField
          value={keywords}
          onChange={changeKeywords}
          placeholder="Keywords"
          error={errors[0]}
          isMandatory
        />
        <TextField
          value={asin}
          onChange={changeAsin}
          placeholder="ASIN"
          error={errors[1]}
          isMandatory
        />
        <Button
          text="Create Link"
          onClick={getResult}
          isForm
          customClass="create-btn"
        />
      </div>
      <ResultField
        resultUrl={result}
        handleCopy={() => handleClickButton("copy", trackTitle)}
        handleOpen={() => handleClickButton("open", trackTitle)}
      />
    </div>
  );
};

HiddenKeywords.propTypes = {
  marketplace: PropTypes.string,
  trackTitle: PropTypes.string,
  handleClickButton: PropTypes.func,
  initialState: PropTypes.object,
  setInitialState: PropTypes.func,
};

export default HiddenKeywords;
