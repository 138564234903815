import React from "react"
import PNG from "../../../assets/images/raster/components/ProductListings/listing@1200.png"
import PNG_2x from "../../../assets/images/raster/components/ProductListings/listing@1200_2x.png"
import PNG_992 from "../../../assets/images/raster/components/ProductListings/listing@992.png"
import PNG_992_2x from "../../../assets/images/raster/components/ProductListings/listing@992_2x.png"
import PNG_768 from "../../../assets/images/raster/components/ProductListings/listing@768_asu.png"
import PNG_768_2x from "../../../assets/images/raster/components/ProductListings/listing@768_2x_asu.png"
import PNG_Mob from "../../../assets/images/raster/components/ProductListings/listing@mob.png"
import PNG_Mob_2x from "../../../assets/images/raster/components/ProductListings/listing@mob_2x.png"

import WEBP from "../../../assets/images/raster/components/ProductListings/listing@1200.webp"
import WEBP_2x from "../../../assets/images/raster/components/ProductListings/listing@1200_2x.webp"
import WEBP_992 from "../../../assets/images/raster/components/ProductListings/listing@992.webp"
import WEBP_992_2x from "../../../assets/images/raster/components/ProductListings/listing@992_2x.webp"
import WEBP_768 from "../../../assets/images/raster/components/ProductListings/listing@768_asu.webp"
import WEBP_768_2x from "../../../assets/images/raster/components/ProductListings/listing@768_2x_asu.webp"
import WEBP_Mob from "../../../assets/images/raster/components/ProductListings/listing@mob.webp"
import WEBP_Mob_2x from "../../../assets/images/raster/components/ProductListings/listing@mob_2x.webp"

const SCREENSHOT_CONTENT = {
    PNG,
    PNG_2x,
    PNG_992,
    PNG_992_2x,
    PNG_768,
    PNG_768_2x,
    PNG_Mob,
    PNG_Mob_2x,
    WEBP,
    WEBP_2x,
    WEBP_992,
    WEBP_992_2x,
    WEBP_768,
    WEBP_768_2x,
    WEBP_Mob,
    WEBP_Mob_2x,
}
export default SCREENSHOT_CONTENT
