import React from "react";
import TrackedLink from "../../Analytics/TrackedLink";
import SCREENSHOT_CONTENT from "./index.content";
import Screen from "./Screen";
import "./index.scss";

const ProductListings = () => {
  return (
    <section className="PgASU-PL">
      <div className="container">
        <h2 className="PgASU-PL__title">
          Are you trying to create a strong and effective listing for your own
          Amazon product?
        </h2>
        <p className="PgASU-PL__subtitle">
          Try Sellerhook’s Listing Creation Service!
        </p>
        <Screen screenshot={SCREENSHOT_CONTENT} classes="PgASU-PL-Screen" />
        <p className="PgASU-PL__description PgASU-PL__description_bottom">
          The team of experts, who have over seven years of experience in
          product and keyword research, can provide you with a winning listing
          that will help your product rank higher in organic visibility.
        </p>
        <p className="PgASU-PL__description">
          Whether you're launching a new product or optimizing an existing one,
          you’ll get a high-quality listing with a proven text structure that
          includes keywords based on your specific business needs.
        </p>
        <TrackedLink
          category="AmazonSuperUrlsLP"
          action="SEE DETAILS"
          class="PgASU-PL__link"
          target
          path="https://listing.sellerhook.com/?utm_source=site&utm_medium=amzscout&utm_campaign=super-urls"
        >
          SEE DETAILS
        </TrackedLink>
      </div>
    </section>
  );
};

export default ProductListings;
