import React, { useContext, useEffect, useRef, useState } from "react";
import { MediaAuthContext } from "../store/context/media-auth-context";
import OgImg from "../assets/images/raster/pages/amazon-super-urls/common/mainscreen_super_url_tool.png";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import LinkGeneratorVariants from "../components/amazon-super-urls/variants/indexVariants";
import HomeIntro from "../components/amazon-super-urls/HomeIntro";
import Pros from "../components/amazon-super-urls/Pros";
import HintBlock from "../components/amazon-super-urls/HintBlock";
import HowUseGenerator from "../components/amazon-super-urls/HowUseGenerator";
import StandardFormBlockContainer from "../containers/common/forms/StandardFormBlockContainer";
import { PAGES } from "../helpers/constants";
import DiscoverPro from "../components/amazon-super-urls/DiscoverPro";
import ProInstall from "../components/amazon-super-urls/ProInstall";
import ProductListings from "../components/amazon-super-urls/ProductListings";
import "../components/amazon-super-urls/index.scss";

const PgASU = () => {
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  const [isShowYoutubeModal, setIsShowYoutubeModal] = useState(false);
  const [urlYoutubeModal, setUrlYoutubeModal] = useState("");
  const generateUrlRef = useRef(null);

  const closeYoutubeModal = () => setUrlYoutubeModal("");

  useEffect(() => {
    setIsShowYoutubeModal(!!urlYoutubeModal);
  }, [urlYoutubeModal]);

  return (
    <Layout
      isShowYoutubeModal={isShowYoutubeModal}
      closeYoutubeModal={closeYoutubeModal}
      urlYoutubeModal={urlYoutubeModal}
      customClass="PgASU"
    >
      <Seo
        title="Amazon Super URL Generator"
        page="amazon-super-urls"
        manifest="browserconfig.xml"
        ogImg={OgImg}
        description=""
      />
      <HomeIntro />
      <Pros />
      <LinkGeneratorVariants
        generateUrlRef={generateUrlRef}
        setUrlYoutubeModal={setUrlYoutubeModal}
      />
      <HintBlock />
      <HowUseGenerator />
      <DiscoverPro />
      <StandardFormBlockContainer
        buttonTextFirstState="Try AMZScout for free!"
        buttonTextSecondState="TRY AMZSCOUT PRO EXTENSION FOR FREE"
        page={PAGES.LINK_GENERATOR}
        customClass={isAuth ? "PgASU-Form-secondState" : "PgASU-Form"}
        title="Try the Most Accurate Tool for Product Research and Find Unsaturated Niches"
        showMobileProPopup
      />
      <ProInstall />
      <ProductListings />
    </Layout>
  );
};

export default PgASU;
