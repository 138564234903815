import React, { useEffect, useState } from "react";
import ResultField from "../ResultField";
import {
  checkValue,
  transformKeywordsStringToUrlFormat,
} from "../../../helpers";
import TextField from "../TextField";
import Button from "../Button";
import PropTypes from "prop-types";
import { LINK_GENERATOR_VARIANTS } from "../../../helpers/constants";

const BrandPrices = (props) => {
  const {
    marketplace,
    trackTitle,
    handleClickButton,
    initialState,
    setInitialState,
  } = props;
  const [brand, setBrand] = useState(initialState?.brand || "");
  const [keywords, setKeywords] = useState(initialState?.keywords || "");
  const [minPrice, setMinPrice] = useState(initialState?.minPrice || "");
  const [maxPrice, setMaxPrice] = useState(initialState?.maxPrice || "");
  const [errors, setErrors] = useState(initialState?.errors || []);
  const [result, setResult] = useState(initialState?.result || "");

  useEffect(() => {
    setInitialState(LINK_GENERATOR_VARIANTS.BRAND, {
      brand,
      keywords,
      minPrice,
      maxPrice,
      errors,
      result,
    });
  }, [brand, keywords, minPrice, maxPrice, errors, result]);

  const changeBrand = (event) => setBrand(event.target.value);
  const changeKeywords = (event) => setKeywords(event.target.value);
  const changeMinPrice = (event) => setMinPrice(event.target.value);
  const changeMaxPrice = (event) => setMaxPrice(event.target.value);
  const getResult = () => {
    const errorsArray = fullChecking();
    const isErrors = !!errorsArray.filter(Boolean).length;
    if (!isErrors) {
      const formattedKeywords = transformKeywordsStringToUrlFormat(keywords);
      const formattedMinPrice = minPrice ? `&low-price=${minPrice}` : "";
      const formattedMaxPrice = maxPrice ? `&high-price=${maxPrice}` : "";
      const res = `https://www.${marketplace}/s/ref=nb_sb_noss_2?url=search-alias%3Daps&field-keywords=${formattedKeywords}&field-brand=${encodeURI(
        brand
      )}${formattedMinPrice}${formattedMaxPrice}`;
      setResult(res);
      handleClickButton("create", `${trackTitle}_success`);
    } else {
      setResult("");
      handleClickButton("create", `${trackTitle}_failed`);
    }
  };

  const fullChecking = () => {
    const errors = [
      checkValue("brand", brand, true),
      checkValue("keywords", keywords, true),
      checkValue("price", minPrice, false),
      checkValue("price", maxPrice, false),
    ];
    setErrors(errors);
    return errors;
  };

  return (
    <div className="variant-content">
      <div className="variant-content__example">
        Example: https://www.{marketplace}
        /s/ref=nb_sb_noss_2?url=search-alias%3Daps&field-keywords=sugar+scrub&field-brand=FOREST+HEAL&rh=i:aps,ssx:relevance
      </div>
      <div className="variant-content__form">
        <TextField
          value={brand}
          onChange={changeBrand}
          placeholder="Brand"
          error={errors[0]}
          isMandatory
        />
        <TextField
          value={keywords}
          onChange={changeKeywords}
          placeholder="Keywords"
          error={errors[1]}
          isMandatory
        />
        <TextField
          value={minPrice}
          onChange={changeMinPrice}
          placeholder="Minimum Price"
          error={errors[2]}
        />
        <TextField
          value={maxPrice}
          onChange={changeMaxPrice}
          placeholder="Maximum Price"
          error={errors[3]}
        />
        <Button
          text="Create Link"
          onClick={getResult}
          isForm
          customClass="create-btn"
        />
      </div>
      <ResultField
        resultUrl={result}
        handleCopy={() => handleClickButton("copy", trackTitle)}
        handleOpen={() => handleClickButton("open", trackTitle)}
      />
    </div>
  );
};

BrandPrices.propTypes = {
  marketplace: PropTypes.string,
  trackTitle: PropTypes.string,
  handleClickButton: PropTypes.func,
  initialState: PropTypes.object,
  setInitialState: PropTypes.func,
};

export default BrandPrices;
