import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { LINK_GENERATOR_VARIANTS } from "../../../helpers/constants";
import { MediaAuthContext } from "../../../store/context/media-auth-context";
import ResultField from "../ResultField";
import { checkValue, pasteErrorClasses } from "../../../helpers";
import TextField from "../TextField";
import Button from "../Button";

const CanonicalUrl = (props) => {
  const {
    marketplace,
    trackTitle,
    handleClickButton,
    initialState,
    setInitialState,
  } = props;
  const {
    media: { isTablet },
  } = useContext(MediaAuthContext);
  const [keyword1, setKeyword1] = useState(initialState?.keyword1 || "");
  const [keyword2, setKeyword2] = useState(initialState?.keyword2 || "");
  const [keyword3, setKeyword3] = useState(initialState?.keyword3 || "");
  const [keyword4, setKeyword4] = useState(initialState?.keyword4 || "");
  const [keyword5, setKeyword5] = useState(initialState?.keyword5 || "");
  const [asin, setAsin] = useState(initialState?.asin || "");
  const [errors, setErrors] = useState(initialState?.errors || []);
  const [result, setResult] = useState(initialState?.result || "");

  useEffect(() => {
    setInitialState(LINK_GENERATOR_VARIANTS.CANONICAL_URLS, {
      keyword1,
      keyword2,
      keyword3,
      keyword4,
      keyword5,
      asin,
      errors,
      result,
    });
  }, [keyword1, keyword2, keyword3, keyword4, keyword5, asin, errors, result]);

  const changeKeyword1 = (event) => setKeyword1(event.target.value);
  const changeKeyword2 = (event) => setKeyword2(event.target.value);
  const changeKeyword3 = (event) => setKeyword3(event.target.value);
  const changeKeyword4 = (event) => setKeyword4(event.target.value);
  const changeKeyword5 = (event) => setKeyword5(event.target.value);
  const changeAsin = (event) => setAsin(event.target.value);

  const getResult = () => {
    const errorsArray = fullChecking();
    const isErrors = !!errorsArray.filter(Boolean).length;
    if (!isErrors) {
      const formattedKeywords = [
        keyword1,
        keyword2,
        keyword3,
        keyword4,
        keyword5,
      ]
        .filter(Boolean)
        .join("-");
      const res = `https://www.${marketplace}/${formattedKeywords}/dp/${asin}`;
      setResult(res);
      handleClickButton("create", `${trackTitle}_success`);
    } else {
      setResult("");
      handleClickButton("create", `${trackTitle}_failed`);
    }
  };

  const fullChecking = () => {
    const errors = [
      checkValue("keywords", keyword1, true),
      checkValue("keywords", keyword2, true),
      checkValue("keywords", keyword3, true),
      checkValue("keywords", keyword4, true),
      checkValue("keywords", keyword5, true),
      checkValue("asin", asin, true),
    ];
    setErrors(errors);
    return errors;
  };

  return (
    <div className="variant-content">
      <div className="variant-content__example">
        Example: https://www.{marketplace}
        /Samsung-Galaxy-S10-Screen-Protector/dp/B07NQ2MBSW/
      </div>
      <div className="variant-content__form">
        {isTablet ? (
          <React.Fragment>
            <TextField
              value={keyword1}
              onChange={changeKeyword1}
              placeholder="Keyword 1"
              error={errors[0] || errors[1]}
              isTwoInputs
              customClass={pasteErrorClasses(errors[0], errors[1])}
              secondInputValue={keyword2}
              secondInputOnChange={changeKeyword2}
              secondInputPlaceholder="Keyword 2"
              isMandatory
            />
            <TextField
              value={keyword3}
              onChange={changeKeyword3}
              placeholder="Keyword 3"
              error={errors[2] || errors[3]}
              isTwoInputs
              customClass={pasteErrorClasses(errors[2], errors[3])}
              secondInputValue={keyword4}
              secondInputOnChange={changeKeyword4}
              secondInputPlaceholder="Keyword 4"
              isMandatory
            />
            <TextField
              value={keyword5}
              onChange={changeKeyword5}
              placeholder="Keyword 5"
              error={errors[4] || errors[5]}
              isTwoInputs
              customClass={pasteErrorClasses(errors[4], errors[5])}
              secondInputValue={asin}
              secondInputOnChange={changeAsin}
              secondInputPlaceholder="ASIN"
              isMandatory
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <TextField
              value={keyword1}
              onChange={changeKeyword1}
              placeholder="Keyword 1"
              error={errors[0]}
              isMandatory
            />
            <TextField
              value={keyword2}
              onChange={changeKeyword2}
              placeholder="Keyword 2"
              error={errors[1]}
              isMandatory
            />
            <TextField
              value={keyword3}
              onChange={changeKeyword3}
              placeholder="Keyword 3"
              error={errors[2]}
              isMandatory
            />
            <TextField
              value={keyword4}
              onChange={changeKeyword4}
              placeholder="Keyword 4"
              error={errors[3]}
              isMandatory
            />
            <TextField
              value={keyword5}
              onChange={changeKeyword5}
              placeholder="Keyword 5"
              error={errors[4]}
              isMandatory
            />
            <TextField
              value={asin}
              onChange={changeAsin}
              placeholder="ASIN"
              error={errors[5]}
              isMandatory
            />
          </React.Fragment>
        )}

        <Button
          text="Create Link"
          onClick={getResult}
          isForm
          customClass="create-btn"
        />
      </div>
      <ResultField
        resultUrl={result}
        handleCopy={() => handleClickButton("copy", trackTitle)}
        handleOpen={() => handleClickButton("open", trackTitle)}
      />
    </div>
  );
};

CanonicalUrl.propTypes = {
  marketplace: PropTypes.string,
  trackTitle: PropTypes.string,
  handleClickButton: PropTypes.func,
  initialState: PropTypes.object,
  setInitialState: PropTypes.func,
};

export default CanonicalUrl;
