import React, { useRef } from "react";
import PropTypes from "prop-types";
import Button from "../Button";
import TextField from "../TextField";
import "./index.scss";

const ResultField = (props) => {
  const { resultUrl, customClass, handleCopy, handleOpen } = props;
  const inputEl = useRef(null);

  const copyToClipboard = () => {
    inputEl.current.select();
    document.execCommand("copy");
    handleCopy();
  };

  const openResultUrlInNewTab = () => {
    handleOpen();
    window.open(resultUrl, "_blank");
  };

  return (
    <div className={`PgASU-ResultField ${customClass || ""}`}>
      <TextField
        resultRef={inputEl}
        value={resultUrl}
        isResult
        placeholder={" "}
      />
      <Button
        text="Copy"
        onClick={copyToClipboard}
        disabled={!resultUrl}
        customClass="PgASU-ResultField-button"
        isForm
      />
      <Button
        text="Open"
        onClick={openResultUrlInNewTab}
        disabled={!resultUrl}
        isForm
      />
    </div>
  );
};

ResultField.propTypes = {
  resultUrl: PropTypes.string,
  customClass: PropTypes.string,
  trackTitle: PropTypes.string,
  handleCopy: PropTypes.func,
  handleOpen: PropTypes.func,
};

export default ResultField;
