import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const Button = (props) => {
  const { text, onClick, position, isForm, disabled, customClass = "" } = props;
  const classes = [
    position ? `PgASU-Button-${position}` : "",
    isForm ? "PgASU-Button-form" : "",
    customClass,
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <button
      type="button"
      className={`PgASU-Button ${classes}`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
};
Button.defaultProps = {
  text: "Try for free",
  customClass: "",
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  position: PropTypes.string,
  isForm: PropTypes.bool,
  disabled: PropTypes.bool,
  customClass: PropTypes.string,
};

export default Button;
