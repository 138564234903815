import React, { useEffect, useState } from "react";
import ResultField from "../ResultField";
import { checkValue } from "../../../helpers";
import TextField from "../TextField";
import Button from "../Button";
import PropTypes from "prop-types";
import { LINK_GENERATOR_VARIANTS } from "../../../helpers/constants";

const FieldASIN = (props) => {
  const {
    marketplace,
    trackTitle,
    handleClickButton,
    initialState,
    setInitialState,
  } = props;
  const [asin, setAsin] = useState(initialState?.asin || "");
  const [errors, setErrors] = useState(initialState?.errors || []);
  const [result, setResult] = useState(initialState?.result || "");

  useEffect(() => {
    setInitialState(LINK_GENERATOR_VARIANTS.CLEAN_PRODUCT_PAGE, {
      asin,
      errors,
      result,
    });
  }, [asin, errors, result]);

  const changeAsin = (event) => setAsin(event.target.value);

  const getResult = () => {
    const errorsArray = fullChecking();
    const isErrors = !!errorsArray.length;
    if (!isErrors) {
      const formattedAsins = asin.replace(/ ,|, | /g, ",");
      const res = `https://www.${marketplace}/stores/page/preview?isPreview=1&isSlp=1&asins=${formattedAsins}`;
      setResult(res);
      handleClickButton("create", `${trackTitle}_success`);
    } else {
      setResult("");
      handleClickButton("create", `${trackTitle}_failed`);
    }
  };

  const fullChecking = () => {
    const errors = asin
      .split(" ")
      .map((item) => checkValue("asin", item, true))
      .filter(Boolean);
    setErrors(errors);
    return errors;
  };

  return (
    <div className="variant-content">
      <div className="variant-content__example">
        Example: https://www.{marketplace}
        /stores/page/preview?isPreview=1&isSlp=1&asins=B01CV9G1BO
      </div>
      <div className="variant-content__form">
        <TextField
          customClass="field-big"
          value={asin}
          onChange={changeAsin}
          placeholder="ASIN(s) with space as a separator"
          isMandatory
          error={errors[0]}
        />
        <Button
          text="Create Link"
          onClick={getResult}
          isForm
          customClass="create-btn"
        />
      </div>
      <ResultField
        resultUrl={result}
        handleCopy={() => handleClickButton("copy", trackTitle)}
        handleOpen={() => handleClickButton("open", trackTitle)}
      />
    </div>
  );
};

FieldASIN.propTypes = {
  marketplace: PropTypes.string,
  trackTitle: PropTypes.string,
  handleClickButton: PropTypes.func,
  initialState: PropTypes.object,
  setInitialState: PropTypes.func,
};

export default FieldASIN;
