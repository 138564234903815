import React from "react"
import PNG from "../../../assets/images/raster/pages/amazon-super-urls/DiscoverPro/PgASU-DiscoverPro-img.png"
import PNG_2x from "../../../assets/images/raster/pages/amazon-super-urls/DiscoverPro/PgASU-DiscoverPro-img@2x.png"
import PNG_992 from "../../../assets/images/raster/pages/amazon-super-urls/DiscoverPro/PgASU-DiscoverPro-img_992.png"
import PNG_992_2x from "../../../assets/images/raster/pages/amazon-super-urls/DiscoverPro/PgASU-DiscoverPro-img_992@2x.png"
import PNG_768 from "../../../assets/images/raster/pages/amazon-super-urls/DiscoverPro/PgASU-DiscoverPro-img_768.png"
import PNG_768_2x from "../../../assets/images/raster/pages/amazon-super-urls/DiscoverPro/PgASU-DiscoverPro-img_768@2x.png"
import PNG_Mob from "../../../assets/images/raster/pages/amazon-super-urls/DiscoverPro/PgASU-DiscoverPro-img@mob.png"
import PNG_Mob_2x from "../../../assets/images/raster/pages/amazon-super-urls/DiscoverPro/PgASU-DiscoverPro-img@mob2x.png"

import WEBP from "../../../assets/images/raster/pages/amazon-super-urls/DiscoverPro/PgASU-DiscoverPro-img.webp"
import WEBP_2x from "../../../assets/images/raster/pages/amazon-super-urls/DiscoverPro/PgASU-DiscoverPro-img@2x.webp"
import WEBP_992 from "../../../assets/images/raster/pages/amazon-super-urls/DiscoverPro/PgASU-DiscoverPro-img_992.webp"
import WEBP_992_2x from "../../../assets/images/raster/pages/amazon-super-urls/DiscoverPro/PgASU-DiscoverPro-img_992@2x.webp"
import WEBP_768 from "../../../assets/images/raster/pages/amazon-super-urls/DiscoverPro/PgASU-DiscoverPro-img_768.webp"
import WEBP_768_2x from "../../../assets/images/raster/pages/amazon-super-urls/DiscoverPro/PgASU-DiscoverPro-img_768@2x.webp"
import WEBP_Mob from "../../../assets/images/raster/pages/amazon-super-urls/DiscoverPro/PgASU-DiscoverPro-img@mob.webp"
import WEBP_Mob_2x from "../../../assets/images/raster/pages/amazon-super-urls/DiscoverPro/PgASU-DiscoverPro-img@mob2x.webp"

const PG_ASU_DISCOVER_PRO_CONTENT = {
  screenshot: {
    PNG,
    PNG_2x,
    PNG_992,
    PNG_992_2x,
    PNG_768,
    PNG_768_2x,
    PNG_Mob,
    PNG_Mob_2x,
    WEBP,
    WEBP_2x,
    WEBP_992,
    WEBP_992_2x,
    WEBP_768,
    WEBP_768_2x,
    WEBP_Mob,
    WEBP_Mob_2x,
  },
  featureList: [
    () => <><strong>Monitor sales trends</strong> to select products that will allow you to obtain the highest revenue
      and profit</>,
    () => <><strong>Find a balance</strong> between market demand and competition so you can select the most effective
      products</>,
    () => <><strong>Get extensive data</strong> (sales per month, price dynamics, reviews. and FBA fees) on every Amazon
      product</>
  ]
}
export default PG_ASU_DISCOVER_PRO_CONTENT
