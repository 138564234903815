import React, { useContext, useState } from "react";
import { MediaAuthContext } from "../../../store/context/media-auth-context";
import Select from "react-select";
import BrandASIN from "./brandASIN";
import { trackGa } from "../../../tracking/googleAnalytics";
import BrandPrices from "./brandPrices";
import HiddenKeywords from "./hiddenKeywords";
import FieldASIN from "./fieldASIN";
import CanonicalUrl from "./canonicalUrl";
import AddCart from "./addCart";
import TogetherASINQuantity from "./togetherASINQuantity";
import TargetedAsins from "./targetedASINS";
import QuestionIcon from "../../../assets/images/vector/pages/amazon-super-urls/question-icon.svg";
import track from "../../../tracking/analytics";
import { LINK_GENERATOR_VARIANTS } from "../../../helpers/constants";
import "./indexVariants.scss";

const marketplaces = [
  "amazon.com",
  "amazon.co.uk",
  "amazon.de",
  "amazon.fr",
  "amazon.it",
  "amazon.es",
  "amazon.ca",
  "amazon.in",
  "amazon.com.au",
];
const options = marketplaces.map((item, index) => ({
  value: index,
  label: item,
}));
const selectStyles = ({ isMobile, isTablet }) => ({
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    fontSize: isMobile ? 14 : 18,
    lineHeight: isMobile ? "14px" : "30px",
    color: "#646464",
    padding: "3px 12px",
    borderRadius: "16px",
    margin: "0 14px 8px",
    backgroundColor: isSelected ? "#F7F7F7" : "#fff",
    width: "170px",
    "&::first-letter": {
      textTransform: "uppercase",
    },
    "&:hover": {
      backgroundColor: "#F7F7F7",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    width: isMobile ? 205 : 215,
    minHeight: 32,
    border: state.isFocused ? "1px solid #CECECE" : "1px solid #CECECE",
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #CECECE",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#646464",
    lineHeight: isMobile ? "14px" : "26px",
    fontSize: isMobile ? 14 : 18,
    "&::first-letter": {
      textTransform: "uppercase",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transition: "all .2s ease",
    transform: state.isFocused ? "rotate(180deg)" : null,
    width: 34,
    height: 30,
    padding: "5px 8px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0px 11px",
  }),
  container: (provided, state) => ({
    ...provided,
    marginTop: isTablet ? "8px" : "10px",
  }),
});

const VARIANTS_DEFAULT_INITIAL_STATE = {
  [LINK_GENERATOR_VARIANTS.BRAND]: {
    brand: "",
    keywords: "",
    minPrice: "",
    maxPrice: "",
    errors: [],
    result: "",
  },
  [LINK_GENERATOR_VARIANTS.ASIN]: {
    brand: "",
    asin: "",
    keywords: "",
    errors: [],
    result: "",
  },
};

const LinkGeneratorVariants = ({ generateUrlRef, setUrlYoutubeModal }) => {
  const {
    media: { isTablet, isNotInitMobile },
  } = useContext(MediaAuthContext);

  const [marketplace, setMarketplace] = useState(options[0]);
  const [activeTab, setActiveTab] = useState(0);
  const [isHideAllContent, setIsHideAllContent] = useState(false);
  const [variantsInitialState, setVariantsInitialState] = useState(
    VARIANTS_DEFAULT_INITIAL_STATE
  );
  let timerId = null;
  let isTimerEnable = false;

  const handleClickTab = (software, action, label, index) => {
    const category = "Super_URLs|".concat(software);
    trackGa(action, { category, label });
    track(category, label || "", action);
    if (index === activeTab) {
      setIsHideAllContent(!isHideAllContent);
    } else {
      setIsHideAllContent(false);
    }
    setActiveTab(index);
  };

  const handleClickButton = (action, label) => {
    const category = "Super_URLs|Buttons";
    trackGa(action, { category, label, value: "" });
    track(category, label, action);
  };

  const changeVariantsInitialState = (key, value) => {
    setVariantsInitialState({ ...variantsInitialState, [key]: value });
  };

  const showVideo = (url) => {
    if (!isTimerEnable) {
      isTimerEnable = true;
      timerId = setTimeout(() => {
        setUrlYoutubeModal(url);
        isTimerEnable = false;
      }, 300);
    }
  };

  const onBlurHint = () => {
    isTimerEnable = false;
    clearTimeout(timerId);
  };

  const tabs = [
    {
      title: "2-Step Super URL via Brand",
      description:
        "Direct users to search results that only include products from your brand. Define the results further by also entering keywords. If someone buys your product after clicking the link it will improve your organic search results for those keywords",
      content: (trackTitle) => (
        <BrandPrices
          marketplace={marketplace.label}
          trackTitle={trackTitle}
          handleClickButton={handleClickButton}
          initialState={variantsInitialState[LINK_GENERATOR_VARIANTS.BRAND]}
          setInitialState={(key, value) =>
            changeVariantsInitialState(key, value)
          }
        />
      ),
      trackTitle: "brand",
      video: "https://www.youtube.com/embed/-MYS15GG55Y",
    },
    {
      title: "2-Step Super URL via ASIN",
      description:
        "Send people to a search results page that only includes your product. Add keywords to the search so when someone buys your item it boosts your organic search rankings",
      content: (trackTitle) => (
        <BrandASIN
          marketplace={marketplace.label}
          trackTitle={trackTitle}
          handleClickButton={handleClickButton}
          initialState={variantsInitialState[LINK_GENERATOR_VARIANTS.ASIN]}
          setInitialState={(key, value) =>
            changeVariantsInitialState(key, value)
          }
        />
      ),
      trackTitle: "asin",
      video: "https://www.youtube.com/embed/cvopWaMBNW4",
    },
    {
      title: "2-Step Super URL via Secret Keyword",
      description:
        "Refer users to search results for specific keywords. Use your ASIN as a hidden keyword to ensure your product is the only one that appears",
      content: (trackTitle) => (
        <HiddenKeywords
          marketplace={marketplace.label}
          trackTitle={trackTitle}
          handleClickButton={handleClickButton}
          initialState={variantsInitialState[LINK_GENERATOR_VARIANTS.KEYWORDS]}
          setInitialState={(key, value) =>
            changeVariantsInitialState(key, value)
          }
        />
      ),
      trackTitle: "keywords",
      video: "https://www.youtube.com/embed/kjjZ-xKIVkI",
    },
    {
      title: "Canonical URLs",
      description:
        "Send customers directly to your listing page by using your ASIN. Add keywords into the URL to improve your Amazon organic search rankings",
      content: (trackTitle) => (
        <CanonicalUrl
          marketplace={marketplace.label}
          trackTitle={trackTitle}
          handleClickButton={handleClickButton}
          initialState={
            variantsInitialState[LINK_GENERATOR_VARIANTS.CANONICAL_URLS]
          }
          setInitialState={(key, value) =>
            changeVariantsInitialState(key, value)
          }
        />
      ),
      trackTitle: "canonical_urls",
      video: "https://www.youtube.com/embed/2nlSKUB63uc",
    },
    {
      title: "Add to cart",
      description:
        "Direct users straight to their Amazon shopping cart with your products already added. This reduces the steps they need to take and increases conversions",
      content: (trackTitle) => (
        <AddCart
          marketplace={marketplace.label}
          trackTitle={trackTitle}
          handleClickButton={handleClickButton}
          initialState={variantsInitialState[LINK_GENERATOR_VARIANTS.CART]}
          setInitialState={(key, value) =>
            changeVariantsInitialState(key, value)
          }
        />
      ),
      trackTitle: "cart",
      video: "https://www.youtube.com/embed/aMdfYG0GkDI",
    },
    {
      title: "Buy together",
      description:
        "Refer customers right to their Amazon shopping cart with several of your items already added",
      content: (trackTitle) => (
        <TogetherASINQuantity
          marketplace={marketplace.label}
          trackTitle={trackTitle}
          handleClickButton={handleClickButton}
          initialState={
            variantsInitialState[LINK_GENERATOR_VARIANTS.BUY_TOGETHER]
          }
          setInitialState={(key, value) =>
            changeVariantsInitialState(key, value)
          }
        />
      ),
      trackTitle: "buy_together",
      video: "https://www.youtube.com/embed/77Q7oMnGQnI",
    },
    {
      title: "Targeted ASIN Search URL",
      description:
        "Use your ASINS to send people to an Amazon search results page that only includes a list of your products",
      content: (trackTitle) => (
        <TargetedAsins
          marketplace={marketplace.label}
          trackTitle={trackTitle}
          handleClickButton={handleClickButton}
          initialState={
            variantsInitialState[LINK_GENERATOR_VARIANTS.TARGETED_ASIN_SEARCH]
          }
          setInitialState={(key, value) =>
            changeVariantsInitialState(key, value)
          }
        />
      ),
      trackTitle: "targeted_asin_search",
      video: "https://www.youtube.com/embed/H9HzwoESWeY",
    },
    {
      title: "Clean Product Page Super URL",
      description:
        "Direct people to a version of your listing that contains no ads or product filters, forcing people to only focus on your items. These links work well when promoting your products on social media",
      content: (trackTitle) => (
        <FieldASIN
          marketplace={marketplace.label}
          trackTitle={trackTitle}
          handleClickButton={handleClickButton}
          initialState={
            variantsInitialState[LINK_GENERATOR_VARIANTS.CLEAN_PRODUCT_PAGE]
          }
          setInitialState={(key, value) =>
            changeVariantsInitialState(key, value)
          }
        />
      ),
      trackTitle: "clean_product_page",
      video: "https://www.youtube.com/embed/fwkmwXEZygY",
    },
  ];

  return (
    <section className="variants">
      <div ref={generateUrlRef} className="variants-scroll" />
      <div className="container">
        <div className="variants-block">
          <div className="variants-header">
            <div className="variants__title">Choose Your Marketplace</div>
            <Select
              options={options}
              value={marketplace}
              onChange={setMarketplace}
              styles={selectStyles({ isMobile: isNotInitMobile, isTablet })}
              isSearchable={false}
            />
          </div>

          {isNotInitMobile ? (
            <div className="variants-body-mobile">
              {tabs.map(
                ({ title, description, content, trackTitle, video }, index) => (
                  <div className="variants-item" key={title}>
                    <div
                      className={`variants-item__tab ${
                        activeTab === index && !isHideAllContent ? "orange" : ""
                      }`}
                      onClick={(e) =>
                        handleClickTab("Tabs", trackTitle, "", index)
                      }
                    >
                      {title}
                    </div>
                    {activeTab === index && !isHideAllContent ? (
                      <div className="variants-item__content">
                        <div className="variants-item__content__description">
                          {description}
                          <img
                            className="variants-tabs__tab__img"
                            src={QuestionIcon}
                            alt=""
                            onClick={() => setUrlYoutubeModal(video)}
                            loading="lazy"
                          />
                        </div>
                        {content(trackTitle)}
                      </div>
                    ) : null}
                  </div>
                )
              )}
            </div>
          ) : (
            <div className="variants-body">
              <div className="variants-tabs">
                {tabs.map(
                  ({ title, description, trackTitle, video }, index) => (
                    <div
                      key={title}
                      className={`variants-tabs__tab ${
                        activeTab === index ? "variants-tabs__tab-active" : ""
                      }`}
                      onClick={() =>
                        handleClickTab("Tabs", trackTitle, "", index)
                      }
                    >
                      <img
                        className="variants-tabs__tab__img"
                        src={QuestionIcon}
                        alt=""
                        onMouseEnter={() => showVideo(video)}
                        onMouseLeave={onBlurHint}
                        loading="lazy"
                      />
                      <div className="variants-tabs__tab__title">{title}</div>
                      <div className="variants-tabs__tab__description">
                        {description}
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="variants-content">
                {tabs[activeTab]?.content(tabs[activeTab].trackTitle)}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default LinkGeneratorVariants;
