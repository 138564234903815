import React from "react";
import "./index.scss";

const HintBlock = () => {
  return (
    <section className="PgASU-HintBlock">
      <div className="container">
        <p className="PgASU-HintBlock__text">
          Refer interested customers to Amazon search results that only include
          your products. Include keywords in your URL for which you’d like your
          products to rank. When people buy your items after using these links,
          your search rankings will improve, increasing traffic and sales for
          your listings.
        </p>
      </div>
    </section>
  );
};

export default HintBlock;
