import React from "react";
import PG_ASU_DISCOVER_PRO_CONTENT from "./index.content";
import DiscoverProScreen from "./DiscoverProScreen";
import "./index.scss";

const DiscoverPro = () => {
  const { screenshot, featureList } = PG_ASU_DISCOVER_PRO_CONTENT;
  return (
    <section className="PgASU-DiscoverPro">
      <div className="container">
        <h2 className="PgASU-DiscoverPro__title">
          Discover AMZScout PRO Extension for Chrome and Edge browsers now!
        </h2>
        <p className="PgASU-DiscoverPro__subTitle">
          Get this innovative Amazon Product Research Tool for free
        </p>
        <div className="PgASU-DiscoverPro__info">
          <DiscoverProScreen
            screenshot={screenshot}
            classes="PgASU-DiscoverPro__screenshot"
          />
          <ul className="PgASU-DiscoverPro__list">
            {featureList.map((text, i) => (
              <li key={i} className="PgASU-DiscoverPro-item">
                <div className="PgASU-DiscoverPro-item__marker" />
                <p className="PgASU-DiscoverPro-item__text">{text()}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default DiscoverPro;
