import React from "react";
import PG_ASU_HOW_USE_GENERATOR_CONTENT from "./index.content";
import "./index.scss";

const HowUseGenerator = () => {
  return (
    <section className="PgASU-HowUseGenerator">
      <div className="container">
        <h2 className="PgASU-HowUseGenerator__title">
          How to Use the Amazon Super URLs Generator
        </h2>
        <div className="PgASU-HowUseGenerator__info">
          {PG_ASU_HOW_USE_GENERATOR_CONTENT.map((text, i) => (
            <div className="PgASU-HowUseGenerator-item" key={i}>
              <p className="PgASU-HowUseGenerator-item__number">{`0${
                i + 1
              }.`}</p>
              <p className="PgASU-HowUseGenerator-item__text">{text()}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowUseGenerator;
