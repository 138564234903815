import React, { useEffect, useState } from "react";
import ResultField from "../ResultField";
import {
  transformKeywordsStringToUrlFormat,
  checkValue,
} from "../../../helpers";
import "./variantContent.scss";
import TextField from "../TextField";
import Button from "../Button";
import PropTypes from "prop-types";
import { LINK_GENERATOR_VARIANTS } from "../../../helpers/constants";

const BrandASIN = (props) => {
  const {
    marketplace,
    trackTitle,
    handleClickButton,
    initialState,
    setInitialState,
  } = props;
  const [brand, setBrand] = useState(initialState?.brand || "");
  const [keywords, setKeywords] = useState(initialState?.keywords || "");
  const [asin, setAsin] = useState(initialState?.asin || "");
  const [errors, setErrors] = useState(initialState?.errors || []);
  const [result, setResult] = useState(initialState?.result || "");

  useEffect(() => {
    setInitialState(LINK_GENERATOR_VARIANTS.ASIN, {
      brand,
      keywords,
      asin,
      errors,
      result,
    });
  }, [brand, keywords, asin, errors, result]);

  const changeBrand = (event) => setBrand(event.target.value);
  const changeKeywords = (event) => setKeywords(event.target.value);
  const changeAsin = (event) => setAsin(event.target.value);
  const getResult = () => {
    const errorsArray = fullChecking();
    const isErrors = !!errorsArray.filter(Boolean).length;
    if (!isErrors) {
      const formattedKeywords = transformKeywordsStringToUrlFormat(keywords);
      const formattedBrand = `&field-brand=${encodeURI(brand)}`;
      const formattedAsin = asin ? `&field-asin=${asin}` : "";
      const res = `https://www.${marketplace}/s/ref=nb_sb_noss_2?url=search-alias%3Daps&field-keywords=${formattedKeywords}${formattedBrand}${formattedAsin}`;
      setResult(res);
      handleClickButton("create", `${trackTitle}_success`);
    } else {
      setResult("");
      handleClickButton("create", `${trackTitle}_failed`);
    }
  };

  const fullChecking = () => {
    const errors = [
      checkValue("brand", brand, true),
      checkValue("keywords", keywords, true),
      checkValue("asin", asin, false),
    ];
    setErrors(errors);
    return errors;
  };

  return (
    <div className="variant-content">
      <div className="variant-content__example">
        Example: https://www.{marketplace}
        /s/ref=nb_sb_noss_2?url=search-alias%3Daps&amp;field-keywords=sugar+scrub&amp;field-brand=FOREST+HEAL&amp;rh=i:aps,ssx:relevance
      </div>
      <div className="variant-content__form">
        <TextField
          value={brand}
          onChange={changeBrand}
          placeholder="Brand"
          error={errors[0]}
          isMandatory
        />
        <TextField
          value={keywords}
          onChange={changeKeywords}
          placeholder="Keywords"
          error={errors[1]}
          isMandatory
        />
        <TextField
          value={asin}
          onChange={changeAsin}
          placeholder="ASIN"
          error={errors[2]}
        />
        <Button
          text="Create Link"
          onClick={getResult}
          isForm
          customClass="create-btn"
        />
      </div>
      <ResultField
        resultUrl={result}
        handleCopy={() => handleClickButton("copy", trackTitle)}
        handleOpen={() => handleClickButton("open", trackTitle)}
      />
    </div>
  );
};

BrandASIN.propTypes = {
  marketplace: PropTypes.string,
  trackTitle: PropTypes.string,
  handleClickButton: PropTypes.func,
  initialState: PropTypes.object,
  setInitialState: PropTypes.func,
};

export default BrandASIN;
